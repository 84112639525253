import React, { useRef, useEffect, useState } from "react";
import Rellax from "rellax";
import Tilt from "react-parallax-tilt";
const spaceShuttle = require("../images/space-shuttle.png");
const launchpad = require("../images/launchpad.png");
const sky = require("../images/sky.png");

const ShuttleLaunchpad: React.FC<{ className?: string }> = ({
  className = "",
}) => {
  const shuttle = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (shuttle) {
      new Rellax(shuttle.current, {
        speed: 11,
      });
    }
  }, [shuttle]);

  return (
    <Tilt
      className={`${className} w-full md:w-auto`}
      style={{
        transformStyle: "preserve-3d",
        transform: "perspective(1000px)",
      }}
      tiltMaxAngleX={15}
      tiltMaxAngleY={15}
      perspective={800}
      transitionSpeed={1500}
      scale={1}
      gyroscope={true}
    >
      <img
        src={sky}
        alt="sky"
        className=" inset-0 w-full md:w-auto md:h-10-vw"
      />
      <img
        src={launchpad}
        alt="launchpad"
        className="absolute inset-0 w-full md:w-auto md:h-10-vw"
        style={{
          transform: "translateZ(30px)",
        }}
      />
      <img
        ref={shuttle}
        src={spaceShuttle}
        alt="space-shuttle"
        className="absolute inset-0 w-full md:w-auto md:h-10-vw top-12 md:top-18"
        style={{
          transform: "translateZ(50px)",
        }}
      />
    </Tilt>
  );
};

export default ShuttleLaunchpad;
