import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ShuttleLaunchpad from "../components/shuttle-lauchpad";
import Button from "../components/button";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Link } from "gatsby";
import UtilizeOurTypeWriter from "../components/TypeWriterTexts/utilize-our";
import ToFoundersTypeWriter from "../components/TypeWriterTexts/to-founders";

const storyDetails = [
  {
    year: "2011",
    text:
      "Apostolos co-founds e-food and Doctoranytime.  George joins forces with Apostolos into investing as angel investor in Beat (acquired in 2017 by Daimler).",
  },
  {
    year: "2016",
    text:
      "After 5 years of entrepreneurial and angel investing activity and some successful exits, VF1 a €20M euro fund is born to back ambitious teams and help them build international market leaders.",
  },
  {
    year: "2018",
    text:
      "VF2 is launched with a size of €50M building on the traction and valuable learnings gained from VF1.",
  },
  {
    year: "2021",
    text:
      "VF3 launched its latest €100M fund, building on its quest to become a strong European Seed stage investor.",
  },
];
const StoryYear: React.FC<{ year: string; text: string; last: boolean }> = ({
  year,
  text,
  last = false,
}) => (
  <div className="relative pr-14">
    <div
      className="absolute w-2 h-2 rounded-full border-2 border-primary border-solid bg-white"
      style={{ top: "-5px", left: "-5px" }}
    ></div>
    <div
      className={`flex flex-col md:pt-4 -mt-5 ml-6 ${
        last ? "" : "mb-12"
      } md:m-0`}
    >
      <span className="text-primary text-2xl pb-2">{year}</span>
      <p className="text-sm">{text}</p>
    </div>
  </div>
);

const Section: React.FC<{ heading: string; noBottomBorder?: boolean }> = ({
  heading,
  children,
  noBottomBorder = false,
}) => (
  <>
    <div className="mb-12 md:mb-14">
      <h2 className="text-6xl font-bold">{heading}</h2>
    </div>
    <div
      className={`${
        noBottomBorder
          ? ""
          : "border-b-2 border-solid border-primary pb-14 md:pb-24"
      } mb-14 md:mb-20  `}
    >
      {children}
    </div>
  </>
);
const AboutUs: React.FC<{ data: any }> = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="About"
        description="We are Entrepreneurs and Angel investors turned into Venture Capitalists."
      />
      <div className="grid row-gap-8 md:row-gap-0 md:grid-rows-1 md:grid-cols-2 mb-14 md:mb-24 lg:-mx-32 overflow-hidden lg:pl-16 pt-8 md:pt-12">
        <ShuttleLaunchpad />
        <div className="flex flex-col md:justify-center  lg:pr-32 row-start-1 md:row-start-auto pt-1-vw ">
          <h1 className="text-2xl md:text-4xl-vw font-bold  mb-8">
            We are Entrepreneurs and Angel investors turned into Venture
            Capitalists
          </h1>
          <Link to="/team">
            <Button secondary className="w-34 text-sm">
              Meet the Team
            </Button>
          </Link>
        </div>
      </div>
      <Section heading={"Our Story"}>
        <div className="grid md:grid-cols-4 border-l-2 md:border-t-2 md:border-l-0 border-primary border-solid mb-12">
          {storyDetails.map((story, index) => (
            <StoryYear
              key={story.year}
              year={story.year}
              text={story.text}
              last={storyDetails.length === index + 1}
            />
          ))}
        </div>
        <div className="md:flex md:flex-row-reverse ">
          <h3 className="text-xl mb-4 md:text-2xl md:leading-8 lg:text-4xl lg:leading-10  md:text-right md:w-5/12">
            We <mark className="bg-primary">support ambitious founders</mark>{" "}
            throughout their journey.
          </h3>
          <p className="text-sm md:w-7/12">
            Apostolos, a serial entrepreneur who co-founded e-shop, e-food, and
            doctoranytime, turned into an angel investor and along with George,
            invested in Beat in 2011. After a couple of notable exits (e-food
            acquired by Delivery Hero and Beat (currently Freenow) acquired by
            Daimler), Apostolos and George identified the gap in seed funding in
            Greece and launched VF1. VentureFriends was born during the tough
            times of the Greek crisis with the mission to back ambitious tech
            entrepreneurs. We have supported some inspiring teams out of Greece
            and over the years, expanded our coverage to Europe with offices in
            London and Warsaw. We want to be among the first institutional
            partners to stellar founding teams across Europe and join their
            journey to build the next global leaders in their sectors. We know
            that it a long and hilly ride, and we will do everything we can to
            be there and support.
          </p>
        </div>
      </Section>
      <Section heading={"Why us"}>
        <div className="grid items-center font-medium md:grid-cols-2 md:row-gap-16">
          <Img
            fluid={data.whyUsOne.nodes[0].fluid}
            alt="we believe in great vision"
            className="w-full h-auto object-cover"
          />
          <h3 className="text-xl mb-16 md:mb-0 my-4 md:my-0 md:pl-20  md:text-2xl md:leading-8 lg:text-4xl lg:leading-10 ">
            We have <span className="bg-primary"> been there</span>, we&nbsp;
            <span className="bg-primary">understand</span> what it takes &amp;
            we view <span className="bg-primary">founders as partners</span>.
          </h3>
          <Img
            fluid={data.whyUsTwo.nodes[0].fluid}
            alt="we listen to our founders"
            className="w-full h-auto object-cover md:col-start-2 md:row-start-2"
          />
          <div className=" my-4 md:my-auto md:col-start-1 md:row-start-2 md:pr-20">
            <h3 className="text-xl md:text-2xl md:leading-8 lg:text-4xl mb-4 lg:leading-10">
              We{" "}
              <strong>
                <ToFoundersTypeWriter />
              </strong>
              our founders and utilise our{" "}
              <strong>
                <UtilizeOurTypeWriter />
              </strong>
              in their favour
            </h3>
            <p className="text-sm">
              We are proud of our name which was actually suggested by one of
              our founders. We are founder first investors, getting our hands
              dirty from day one and we are next to you in the good and the bad
              times. All startups will get through tough times and you need to
              think twice who you would have next to you. Ask our founders 😉
            </p>
          </div>
        </div>
      </Section>
      <Section heading={"Investment Focus"} noBottomBorder={true}>
        <div className="grid  md:grid-cols-2">
          <Img
            fluid={data.investmentFocus.nodes[0].fluid}
            alt="investment focus"
            className="w-full h-auto object-cover"
          />
          <div className="my-4 md:my-auto md:pl-20  ">
            <span className="text-xl md:text-2xl md:leading-8 lg:text-4xl lg:leading-10 mb-4">
              We are an early stage investor investing in{" "}
              <mark className="bg-primary">Seed and Series A</mark>
              &nbsp;startups.
            </span>
            <p className="text-sm mb-4">
              We are attracted to scalable B2C and B2B businesses that address
              large markets and can develop a strong moat over time.
              Marketplaces are in our DNA, but we also love SaaS models, while
              in terms of verticals we have a sweet spot for FinTech and
              PropTech among others.
            </p>

            {/* <Link to="/team">
              <Button secondary className="w-34 text-sm">
                About
              </Button>
            </Link> */}
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default AboutUs;
export const pageQuery = graphql`
  query getImages {
    whyUsOne: allImageSharp(
      filter: { fluid: { originalName: { eq: "why-us-1@3x.jpg" } } }
    ) {
      nodes {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whyUsTwo: allImageSharp(
      filter: { fluid: { originalName: { eq: "why-us-2@3x.jpg" } } }
    ) {
      nodes {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    investmentFocus: allImageSharp(
      filter: { fluid: { originalName: { eq: "investment-focus.jpg" } } }
    ) {
      nodes {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
